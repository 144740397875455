<template>
  <div class="formSection" :id="activeConstId" v-if="fetched">
    <v-form ref="form">
      <v-expansion-panels :inset="$vuetify.breakpoint.mdAndUp" v-model="panel">
        <!-- receiver information -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Receiver Information
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="full">
              <div dense class="l_1">
                <div class="courier">
                  <v-autocomplete
                    @change="
                      calculatePrice();
                      getAvailableServices();
                      validateCountry(
                        activeShipmentErrorData.receiverInfo.country,
                        activeShipmentData.receiverInfo.country
                      );
                      validateTotalWeight(
                        activeShipmentErrorData.totalWeight,
                        activeShipmentData
                      );
                    "
                    dense
                    label="Country"
                    placeholder="Enter your Country"
                    outlined
                    color="primary"
                    class="mr-4"
                    return-object
                    item-text="name"
                    v-model="activeShipmentData.receiverInfo.country"
                    prepend-inner-icon="mdi-alpha-c-circle"
                    :items="calcData.countries"
                    :error-messages="
                      activeShipmentErrorData.receiverInfo.country.msg
                    "
                    :error="activeShipmentErrorData.receiverInfo.country.error"
                  ></v-autocomplete>

                  <v-autocomplete
                    @change="
                      validateCourierService(
                        activeShipmentErrorData.receiverInfo.courierService,
                        activeShipmentData.receiverInfo.courierService
                      );
                      calculatePrice();
                      validateMobile(
                        activeShipmentErrorData.receiverInfo.mobile,
                        activeShipmentData.receiverInfo.mobile
                      );
                      getPackingMethod();
                      changePhoneLabel();
                      validateTotalWeight(
                        activeShipmentErrorData.totalWeight,
                        activeShipmentData
                      );
                    "
                    dense
                    label="Courier Service"
                    placeholder="Enter your service"
                    outlined
                    :menu-props="{ maxHeight: 400 }"
                    color="primary"
                    class="mr-4"
                    v-model="activeShipmentData.receiverInfo.courierService"
                    return-object
                    item-text="title"
                    prepend-inner-icon="mdi-email-send-outline"
                    :items="exportServices"
                    :error-messages="
                      activeShipmentErrorData.receiverInfo.courierService.msg
                    "
                    :error="
                      activeShipmentErrorData.receiverInfo.courierService.error
                    "
                  ></v-autocomplete>
                </div>
              </div>
              <!-- l1 -->
              <div class="l_1">
                <div class="Name">
                  <v-text-field
                    @keyup="
                      validateName(
                        activeShipmentErrorData.receiverInfo.name,
                        activeShipmentData.receiverInfo.name
                      )
                    "
                    required
                    dense
                    label="First Name"
                    placeholder="Enter First name"
                    outlined
                    color="primary"
                    class="mr-4"
                    prepend-inner-icon="mdi-account-box-outline"
                    v-model="activeShipmentData.receiverInfo.name"
                    :error-messages="
                      activeShipmentErrorData.receiverInfo.name.msg
                    "
                    :error="activeShipmentErrorData.receiverInfo.name.error"
                  ></v-text-field>
                </div>
                <div class="lastName">
                  <v-text-field
                    @keyup="
                      validateLastName(
                        activeShipmentErrorData.receiverInfo.lastName,
                        activeShipmentData.receiverInfo.lastName
                      )
                    "
                    dense
                    label="Last Name"
                    placeholder="Enter last name"
                    outlined
                    color="primary"
                    class="mr-4"
                    prepend-inner-icon="mdi-account-box-outline"
                    v-model="activeShipmentData.receiverInfo.lastName"
                    :error-messages="
                      activeShipmentErrorData.receiverInfo.lastName.msg
                    "
                    :error="activeShipmentErrorData.receiverInfo.lastName.error"
                  ></v-text-field>
                </div>

                <div class="vatOrTaxNO">
                  <v-text-field
                    @keyup="
                      validateVatOrTax(
                        activeShipmentErrorData.receiverInfo.vatOrTax,
                        activeShipmentData.receiverInfo.vatOrTax
                      )
                    "
                    dense
                    label="VAT/TAX"
                    placeholder="Enter VAT/TAX ID"
                    outlined
                    color="primary"
                    class="mr-4"
                    prepend-inner-icon="mdi-account-box-outline"
                    v-model="activeShipmentData.receiverInfo.vatOrTax"
                    :error-messages="
                      activeShipmentErrorData.receiverInfo.vatOrTax.msg
                    "
                    :error="activeShipmentErrorData.receiverInfo.vatOrTax.error"
                  ></v-text-field>
                </div>
              </div>
              <!-- l_1 -->

              <div class="l_1">
                <div class="mobile">
                  <v-text-field
                    @keyup="
                      validateMobile(
                        activeShipmentErrorData.receiverInfo.mobile,
                        activeShipmentData.receiverInfo.mobile
                      )
                    "
                    required
                    dense
                    :label="phoneFieldLabel"
                    placeholder="Enter Mobile Number"
                    outlined
                    color="primary"
                    class="mr-4"
                    prepend-inner-icon="mdi-cellphone"
                    v-model="activeShipmentData.receiverInfo.mobile"
                    :error-messages="
                      activeShipmentErrorData.receiverInfo.mobile.msg
                    "
                    :error="activeShipmentErrorData.receiverInfo.mobile.error"
                  ></v-text-field>
                </div>
                <div class="email">
                  <v-text-field
                    @keyup="
                      validateEmail(
                        activeShipmentErrorData.receiverInfo.email,
                        activeShipmentData.receiverInfo.email
                      )
                    "
                    required
                    dense
                    label="Email (Optional)"
                    placeholder="Enter Email Id  "
                    outlined
                    color="primary"
                    class="mr-4"
                    prepend-inner-icon="mdi-email-multiple"
                    v-model="activeShipmentData.receiverInfo.email"
                    :error-messages="
                      activeShipmentErrorData.receiverInfo.email.msg
                    "
                    :error="activeShipmentErrorData.receiverInfo.email.error"
                  ></v-text-field>
                </div>
                <v-text-field
                  @keyup="
                    validateCompany(
                      activeShipmentErrorData.receiverInfo.company,
                      activeShipmentData.receiverInfo.company
                    )
                  "
                  required
                  dense
                  label="Company (Optional)"
                  placeholder="Enter Company"
                  outlined
                  color="primary"
                  class="mr-4"
                  prepend-inner-icon="mdi-office-building-marker"
                  :error-messages="
                    activeShipmentErrorData.receiverInfo.company.msg
                  "
                  :error="activeShipmentErrorData.receiverInfo.company.error"
                  v-model="activeShipmentData.receiverInfo.company"
                ></v-text-field>
              </div>
              <!-- l_1 -->

              <div class="l_1">
                <div class="address-1">
                  <v-text-field
                    @keyup="
                      validateAddress1(
                        activeShipmentErrorData.receiverInfo.address1,
                        activeShipmentData.receiverInfo.address1
                      )
                    "
                    required
                    dense
                    label="Address Line 1"
                    placeholder="Enter Address 1"
                    outlined
                    color="primary"
                    class="mr-4"
                    prepend-inner-icon="mdi-email"
                    v-model="activeShipmentData.receiverInfo.address1"
                    :error-messages="
                      activeShipmentErrorData.receiverInfo.address1.msg
                    "
                    hint="Kindly note that we will not be able to ship the item with a PO Box address only via Colombo Mail Economy. Hence, please enter the complete address"
                    :error="activeShipmentErrorData.receiverInfo.address1.error"
                  ></v-text-field>
                </div>
                <div class="address-2">
                  <v-text-field
                    @keyup="
                      validateAddress2(
                        activeShipmentErrorData.receiverInfo.address2,
                        activeShipmentData.receiverInfo.address2
                      )
                    "
                    hint="Kindly note that we will not be able to ship the item with a PO Box address only via Colombo Mail Economy. Hence, please enter the complete address"
                    required
                    dense
                    label="Address Line 2"
                    placeholder="Enter Address 2"
                    outlined
                    color="primary"
                    prepend-inner-icon="mdi-message-text"
                    v-model="activeShipmentData.receiverInfo.address2"
                    class="mr-4"
                    :error-messages="
                      activeShipmentErrorData.receiverInfo.address2.msg
                    "
                    :error="activeShipmentErrorData.receiverInfo.address2.error"
                  ></v-text-field>
                </div>
              </div>
              <div class="l_1">
                <div class="address-1">
                  <v-text-field
                    @keyup="
                      validateAddress3(
                        activeShipmentErrorData.receiverInfo.address3,
                        activeShipmentData.receiverInfo.address3
                      )
                    "
                    required
                    dense
                    label="Address Line 3 (Optional)"
                    placeholder="Enter Address 3"
                    outlined
                    color="primary"
                    class="mr-4"
                    prepend-inner-icon="mdi-message-text"
                    v-model="activeShipmentData.receiverInfo.address3"
                    :error-messages="
                      activeShipmentErrorData.receiverInfo.address3.msg
                    "
                    :error="activeShipmentErrorData.receiverInfo.address3.error"
                  ></v-text-field>
                </div>
                <div class="address-2">
                  <v-text-field
                    @keyup="
                      validateAddress4(
                        activeShipmentErrorData.receiverInfo.address4,
                        activeShipmentData.receiverInfo.address4
                      )
                    "
                    required
                    dense
                    placeholder="Enter Address 4"
                    label="Address Line 4 (optional)"
                    outlined
                    color="primary"
                    prepend-inner-icon="mdi-message-text"
                    v-model="activeShipmentData.receiverInfo.address4"
                    class="mr-4"
                    :error-messages="
                      activeShipmentErrorData.receiverInfo.address4.msg
                    "
                    :error="activeShipmentErrorData.receiverInfo.address4.error"
                  ></v-text-field>
                </div>
              </div>
              <div class="l_1">
                <div class="courier">
                  <v-text-field
                    @keyup="
                      validateZipcode(
                        activeShipmentErrorData.receiverInfo.zipcode,
                        activeShipmentData.receiverInfo.zipcode
                      )
                    "
                    required
                    dense
                    label="ZipCode/PostalCode (optional)"
                    placeholder="Enter Zipcode/Postal Code"
                    outlined
                    color="primary"
                    class="mr-4"
                    prepend-inner-icon="mdi-barcode"
                    :error-messages="
                      activeShipmentErrorData.receiverInfo.zipcode.msg
                    "
                    :error="activeShipmentErrorData.receiverInfo.zipcode.error"
                    v-model="activeShipmentData.receiverInfo.zipcode"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <router-link to="">
              <v-row justify="end" class="pa-4">
                <v-btn color="#923AEA" class="white--text" @click="openTab(1)">
                  Enter item Information
                  <v-icon right dark> mdi-arrow-right-circle </v-icon>
                </v-btn>
              </v-row>
            </router-link>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- item information -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Item Information
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- <v-row>
              <p
                class="mb-6 ml-3"
                v-if="activeShipmentData.receiverInfo.courierService"
              >
                Max weight for
                {{ activeShipmentData.receiverInfo.courierService.title }} is
                {{ activeShipmentData.receiverInfo.courierService.maxWeight }}
                grams
              </p>
            </v-row> -->

            <v-row>
              <v-col cols="12" md="6" class="pa-3">
                <v-text-field
                  @keyup="
                    calculatePrice();
                    validateTotalWeight(
                      activeShipmentErrorData.totalWeight,
                      activeShipmentData
                    );
                  "
                  required
                  dense
                  label="Total Weight (grams)"
                  placeholder="Enter Total Weight in grams"
                  outlined
                  color="primary"
                  prepend-inner-icon="mdi-message-text"
                  v-model="activeShipmentData.totalWeight"
                  :hint="getMaxWeightMsg()"
                  :error-messages="activeShipmentErrorData.totalWeight.msg"
                  :error="activeShipmentErrorData.totalWeight.error"
                ></v-text-field>
              </v-col>
            </v-row>
            <div
              v-for="(item, index) in activeShipmentData.itemInfo"
              :key="index"
              class="l_1"
            >
              <div class="com">
                <!-- left -->
                <div class="itemdes">
                  <v-text-field
                    @keyup="
                      validataItemDiscription(
                        index,
                        activeShipmentErrorData.itemInfo[index].itemDescription,
                        activeShipmentData.itemInfo[index].itemDescription
                      )
                    "
                    required
                    dense
                    label="Item Info"
                    placeholder="Enter item info"
                    outlined
                    color="primary"
                    class="mr-4"
                    prepend-inner-icon="mdi-clipboard-list"
                    v-model="activeShipmentData.itemInfo[index].itemDescription"
                    :error-messages="
                      activeShipmentErrorData.itemInfo[index].itemDescription
                        .msg
                    "
                    :error="
                      activeShipmentErrorData.itemInfo[index].itemDescription
                        .error
                    "
                  ></v-text-field>
                </div>
                <div class="l_1">
                  <div class="courier">
                    <v-autocomplete
                      @change="
                        validatePurpose(
                          index,
                          activeShipmentErrorData.itemInfo[index].purpose,
                          activeShipmentData.itemInfo[index].purpose
                        )
                      "
                      dense
                      label="Purpose"
                      placeholder="Enter purpose"
                      outlined
                      color="primary"
                      class="mr-4"
                      v-model="activeShipmentData.itemInfo[index].purpose"
                      prepend-inner-icon="mdi-alpha-p-circle-outline"
                      :items="purposes"
                      :error-messages="
                        activeShipmentErrorData.itemInfo[index].purpose.msg
                      "
                      :error="
                        activeShipmentErrorData.itemInfo[index].purpose.error
                      "
                    ></v-autocomplete>

                    <v-text-field
                      @keyup="
                        validateItemValue(
                          index,
                          activeShipmentErrorData.itemInfo[index].value,
                          activeShipmentData.itemInfo[index].value
                        )
                      "
                      suffix="LKR"
                      required
                      dense
                      label="Value"
                      placeholder="Enter Value"
                      outlined
                      color="primary"
                      type="number"
                      class="mr-4"
                      prepend-inner-icon="mdi-alpha-v-circle-outline"
                      v-model="activeShipmentData.itemInfo[index].value"
                      :error-messages="
                        activeShipmentErrorData.itemInfo[index].value.msg
                      "
                      :error="
                        activeShipmentErrorData.itemInfo[index].value.error
                      "
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="btn">
                <v-btn
                  depressed
                  class="white--text mb-2"
                  color="#923AEA"
                  @click="addItemInfo(index)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn text class="mt-2" @click="deleteItemInfo(index)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
            <router-link to="">
              <v-row justify="end" class="pa-4">
                <v-btn color="#923AEA" class="white--text" @click="openTab(2)">
                  Enter package Information
                  <v-icon right dark> mdi-arrow-right-circle </v-icon>
                </v-btn>
              </v-row>
            </router-link>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- package information -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            Package Information
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="l_1">
              <div class="left">
                <b v-if="activeShipmentData.packageInfo.packingMethod == 'box'">
                  The greater of either dimensional or actual gross weight will
                  be considered as the chargeable weight
                </b>
                <div>
                  <v-autocomplete
                    @change="
                      validatePackingMethod(
                        activeShipmentErrorData.packageInfo.packingMethod,
                        activeShipmentData.packageInfo.packingMethod
                      )
                    "
                    outlined
                    dense
                    label="Packaging Method"
                    prepend-inner-icon="mdi-package-variant"
                    item-text="name"
                    item-value="id"
                    :items="packingMethods"
                    :error-messages="
                      activeShipmentErrorData.packageInfo.packingMethod.msg
                    "
                    :error="
                      activeShipmentErrorData.packageInfo.packingMethod.error
                    "
                    v-model="activeShipmentData.packageInfo.packingMethod"
                  ></v-autocomplete>
                </div>

                <div
                  class="courier"
                  v-if="activeShipmentData.packageInfo.packingMethod == 'box'"
                >
                  <v-col>
                    <v-text-field
                      @keyup="
                        validateWidth(
                          activeShipmentErrorData.packageInfo.width,
                          activeShipmentData.packageInfo.width
                        )
                      "
                      outlined
                      required
                      label="width"
                      dense
                      :error-messages="
                        activeShipmentErrorData.packageInfo.width.msg
                      "
                      :error="activeShipmentErrorData.packageInfo.width.error"
                      v-model="activeShipmentData.packageInfo.width"
                      prepend-inner-icon="mdi-table-column-width"
                      suffix="cm"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      @keyup="
                        validateLength(
                          activeShipmentErrorData.packageInfo.length,
                          activeShipmentData.packageInfo.length
                        )
                      "
                      required
                      suffix="cm"
                      :error-messages="
                        activeShipmentErrorData.packageInfo.length.msg
                      "
                      :error="activeShipmentErrorData.packageInfo.length.error"
                      v-model="activeShipmentData.packageInfo.length"
                      outlined
                      label="Length"
                      dense
                      prepend-inner-icon="mdi-table-arrow-right"
                    ></v-text-field>
                  </v-col>
                </div>
                <v-col
                  v-if="activeShipmentData.packageInfo.packingMethod == 'box'"
                >
                  <v-text-field
                    @keyup="
                      validateHeight(
                        activeShipmentErrorData.packageInfo.height,
                        activeShipmentData.packageInfo.height
                      )
                    "
                    outlined
                    :error-messages="
                      activeShipmentErrorData.packageInfo.height.msg
                    "
                    :error="activeShipmentErrorData.packageInfo.height.error"
                    v-model="activeShipmentData.packageInfo.height"
                    required
                    label="Height"
                    dense
                    suffix="cm"
                    prepend-inner-icon="mdi-human-male-height"
                  ></v-text-field>
                </v-col>
                <div id="notice">
                  <ol>
                    <li>
                      Above rates are only applicable for the Express Saver
                      Service, if a different service is used the standard rates
                      will be applicable
                    </li>
                    <li>
                      Any fraction of a kilogram over the weight shown takes the
                      next higher weight rate.
                    </li>
                    <li>
                      Shipment Insurance is available at an additional charge
                    </li>
                    <li>
                      Custom duties and taxes are not included in the above
                      rates.
                    </li>
                    <li>
                      <b>
                        The greater of either dimensional or actual gross weight
                        will be considered as the chargeable weight. - The
                        following formula will determine the volumetric
                        weight:<br />
                        Length x Width x Height (cm3) = Vol. Weight (kg)</b
                      >
                    </li>
                    <li>
                      An additional charge will be applicable for large packages
                      & shipments exceeding either: weight/length & dimension.
                    </li>
                    <li>
                      Origin & Destination Duties & Taxes are not included in
                      the tariff
                    </li>
                    <li>
                      Maximum liability for lost/damage/shortage is USD 100 only
                      or Invoice value, which ever is lower
                    </li>
                  </ol>
                </div>
              </div>
              <div
                class="right"
                v-if="activeShipmentData.packageInfo.packingMethod == 'box'"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/colombo-mail.appspot.com/o/imgs%2Fbox%20sample.svg?alt=media&token=bce0a504-0377-41c4-968f-f9b3aa441602"
                />
              </div>
            </div>
            <router-link to="">
              <v-row justify="end" class="pa-4">
                <v-btn color="#923AEA" class="white--text" @click="openTab(3)">
                  Enter Payment Information
                  <v-icon right dark> mdi-checkbox-marked-circle </v-icon>
                </v-btn>
              </v-row>
            </router-link>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- payment information -->
        <v-expansion-panel>
          <v-expansion-panel-header
            >Payment Information</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <h4>Select Prefered Payment Information</h4>
            <v-row>
              <small
                v-if="activeShipmentErrorData.preferedPayMode.error"
                style="color: red"
                class="mt-3 mb-n5 ml-3"
              >
                {{ activeShipmentErrorData.preferedPayMode.msg }}
              </small>
            </v-row>
            <v-row>
              <v-radio-group
                v-model="activeShipmentData.preferedPayMode"
                @change="
                  validatePaymentMethod(
                    activeShipmentErrorData.preferedPayMode,
                    activeShipmentData.preferedPayMode
                  )
                "
              >
                <!-- <v-radio
                  label="Online (3% additional charge)"
                  value="online"
                  class="ml-2"
                ></v-radio> -->
                <v-radio
                  label="Bank Transfer"
                  value="bank_transfer"
                  class="ml-2"
                ></v-radio>
                <v-radio
                  label="Bank Deposit"
                  value="bank_deposit"
                  class="ml-2"
                ></v-radio>

                <v-radio
                  class="ml-2"
                  label="Cash Payment"
                  value="cash"
                ></v-radio>
              </v-radio-group>
            </v-row>
            <router-link to="">
              <v-row justify="end" class="pa-4">
                <v-btn
                  color="#923AEA"
                  class="white--text"
                  @click="openTab(null)"
                >
                  Done
                  <v-icon right dark> mdi-checkbox-marked-circle </v-icon>
                </v-btn>
              </v-row>
            </router-link>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import { exportCalculator } from "../../helper/exportCalculation.helper";
export default {
  props: {
    activeConstId: {
      type: Number,
    },
    validationTrigger: {
      type: Boolean,
    },
    order: {
      type: Array,
    },
    orderErrors: {
      type: Object,
    },
    activeShipmentErrorData: {
      type: Object,
    },
    activeShipmentData: {
      type: Object,
    },
    doValidate: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters(["calcData", "allExportServices"]),
  },

  watch: {
    validationTrigger: function (newValue, oldValue) {
      console.log(newValue, oldValue);
      this.orderValidation();
    },
  },
  created() {
    this.init();
  },
  data() {
    return {
      panel: 0,
      purposes: [
        "Gift",
        "Commercial",
        "Personal",
        "Sample",
        "Return for repair",
        "Return After repair",
      ],
      phoneFieldLabel: "Phone Number",
      packingMethods: [
        { id: "box", name: "Box" },
        { id: "envelope", name: "Envelope" },
      ],

      totalWeight: 0,
      fetched: false,
      exportServices: [],
    };
  },
  methods: {
    ...mapActions([
      "fetchallExportServices",
      "fetchCalculationData",
      "caculateExportPrice",
    ]),

    async init() {
      if (
        this.calcData == undefined ||
        this.calcData == null ||
        this.calcData == {} ||
        this.calcData.length == 0
      ) {
        await this.fetchCalculationData();
        console.log("export calcData", this.calcData);
      }
      if (
        this.allExportServices == [] ||
        this.allExportServices == undefined ||
        this.allExportServices == null ||
        this.allExportServices.length == 0
      ) {
        await this.fetchallExportServices();
      }
      this.fetched = true;
      //get export exportServices
      this.getAvailableServices();
    },

    //get max weight message for total weight
    getMaxWeightMsg() {
      const activeShipmentData = this.activeShipmentData;
      if (activeShipmentData.receiverInfo.courierService)
        return `Max weight for
                ${activeShipmentData.receiverInfo.courierService.title} is
                ${activeShipmentData.receiverInfo.courierService.maxWeight}
                grams`;
      else {
        return "Select courier service before weight to know whether the weight is shipable";
      }
    },

    //next two function are to change the content based o the packing method choose
    getPackingMethod() {
      this.activeShipmentData.packageInfo.packingMethod = null;
      this.packingMethods = [];
      const otherServicePackingMethods = [{ id: "envelope", name: "Envelope" }];
      const colomboExpressPackingMethod = [
        { id: "box", name: "Box" },
        { id: "envelope", name: "Envelope" },
      ];
      if (this.activeShipmentData.receiverInfo.courierService != null)
        if (
          this.activeShipmentData.receiverInfo.courierService.id ==
            "colomboExpress" ||
          this.activeShipmentData.receiverInfo.courierService.id ==
            "colomboEconomy"
        ) {
          this.packingMethods = colomboExpressPackingMethod;
        } else {
          this.packingMethods = otherServicePackingMethods;
        }
      else {
        this.packingMethods = otherServicePackingMethods;
      }
    },
    changePhoneLabel() {
      if (
        this.activeShipmentData.receiverInfo.courierService.id ==
          "colomboExpress" ||
        this.activeShipmentData.receiverInfo.courierService.id ==
          "colomboEconomy"
      ) {
        this.phoneFieldLabel = "Phone number";
      } else {
        this.phoneFieldLabel = "Phone number (optional)";
      }
    },

    //get courier services based on the country selected
    getAvailableServices() {
      this.activeShipmentData.receiverInfo.courierService = null;
      // this.calculatePrice();
      let availableExportServices = [];
      console.log("allExportservices", this.allExportServices);
      const allExportServices = this.allExportServices;
      for (let i = 0; i < 9; i++) {
        const postage = allExportServices[i];
        if (postage.id == "colomboEconomy" || postage.id == "usps") {
          if (this.activeShipmentData.receiverInfo.country.code == "US")
            availableExportServices.push(this.formatPostge(postage));
        } else if (postage.id == "hermies") {
          if (this.activeShipmentData.receiverInfo.country.code == "GB")
            availableExportServices.push(this.formatPostge(postage));
        } else {
          availableExportServices.push(this.formatPostge(postage));
        }
      }

      console.log("export services", this.exportServices);
      console.log("availableExportServices", availableExportServices);
      this.exportServices = availableExportServices;
    },

    formatPostge(postage) {
      // console.log("format postage", postage)
      return {
        name: postage.name,
        title: postage.title,
        id: postage.id,
        addingValue: postage.addingValue,
        minWeight: postage.minWeight,
        maxWeight: postage.maxWeight,
        countries:postage.countries
      };
    },

    //calculate shipment price
    calculatePrice() {
      console.log("Calculate Order", this.activeShipmentData);

      //calc total price

      if (
        this.activeShipmentData.receiverInfo.courierService != null &&
        this.activeShipmentData.receiverInfo.country != null &&
        this.activeShipmentData.totalWeight > 0
      ) {
        this.caculateExportPrice({
          courierId: this.activeShipmentData.receiverInfo.courierService.id,
          to: this.activeShipmentData.receiverInfo.country,
          weight: parseInt(this.activeShipmentData.totalWeight),
        })
          .then((result) => {
            result.forEach((service) => {
              if (
                service.id ==
                this.activeShipmentData.receiverInfo.courierService.id
              ) {
                this.activeShipmentData.payMode = service;
              }
            });

            console.log(
              "this.activeShipmentData.paymode",
              this.activeShipmentData.payMode
            );
          })
          .catch((error) => {
            console.error("error", error);
          });
      }
    },

    openTab(tabNumber) {
      this.panel = tabNumber;
    },
    deleteItemInfo(index) {
      if (this.activeShipmentData.itemInfo.length > 1) {
        console.log(this.activeShipmentData);

        this.activeShipmentData.itemInfo.splice(index, 1);
        this.activeShipmentErrorData.itemInfo.splice(index, 1);
      } else {
        //#create snackbar
        console.log("Item Information cannot be deleted");
      }
    },
    addItemInfo(index) {
      console.log("addItemInfo", index);
      // #limit adding of items
      const defautItemInfo = {
        itemDescription: null,
        purpose: null,
        value: null,
      };
      const defautItemErrorInfo = {
        itemDescription: { error: false, msg: [] },
        purpose: { error: false, msg: [] },
        value: { error: false, msg: [] },
      };
      console.log(this.activeShipmentData);
      this.activeShipmentData.itemInfo.push(defautItemInfo);
      this.activeShipmentErrorData.itemInfo.push(defautItemErrorInfo);
    },

    //TO VALIDATE WHOLE ORDER
    orderValidation() {
      console.log("Validation started");

      this.order.forEach((shipment, index) => {
        this.shipmentValidator(shipment, index);
      });

      let totalErrors = 0;
      let allErrorMsg = [];
      this.orderErrors.errors.forEach((item, index) => {
        totalErrors += item.total;
        const errorMsg = {
          shipment: `Shipment ${index + 1}`,
          messages: item.errorMsg,
        };
        allErrorMsg.push(errorMsg);
      });
      this.orderErrors.total = totalErrors;
      this.orderErrors.allErrorMsg = allErrorMsg;

      console.log("TOTAL ERRORS: ", this.orderErrors.total);
      console.log("ALL ERROR MSG: ", this.orderErrors.allErrorMsg);
      console.log(
        "All Error Msg[0]: ",
        this.orderErrors.allErrorMsg[0].messages.length
      );
    },

    //TO VALIDATE A SHIPMENT
    shipmentValidator(shipmentData, index) {
      let errorData = this.orderErrors.errors[index];
      errorData.total = 0;
      errorData.errorMsg = [];

      //validate reciver info
      this.validateCourierService(
        errorData.receiverInfo.courierService,
        shipmentData.receiverInfo.courierService
      );
      this.validateCountry(
        errorData.receiverInfo.country,
        shipmentData.receiverInfo.country
      );
      this.validateName(
        errorData.receiverInfo.name,
        shipmentData.receiverInfo.name
      );
      this.validateLastName(
        errorData.receiverInfo.lastName,
        shipmentData.receiverInfo.lastName
      );
      this.validateVatOrTax(
        errorData.receiverInfo.vatOrTax,
        shipmentData.receiverInfo.vatOrTax
      );
      this.validateMobile(
        errorData.receiverInfo.mobile,
        shipmentData.receiverInfo.mobile
      );
      this.validateEmail(
        errorData.receiverInfo.email,
        shipmentData.receiverInfo.email
      );
      this.validateAddress1(
        errorData.receiverInfo.address1,
        shipmentData.receiverInfo.address1
      );
      this.validateAddress2(
        errorData.receiverInfo.address2,
        shipmentData.receiverInfo.address2
      );
      this.validateAddress3(
        errorData.receiverInfo.address3,
        shipmentData.receiverInfo.address3
      );
      this.validateAddress4(
        errorData.receiverInfo.address4,
        shipmentData.receiverInfo.address4
      );
      this.validateZipcode(
        errorData.receiverInfo.zipcode,
        shipmentData.receiverInfo.zipcode
      );
      this.validateCompany(
        errorData.receiverInfo.company,
        shipmentData.receiverInfo.company
      );

      //validate weight
      this.validateTotalWeight(errorData.totalWeight, shipmentData);

      //validate item info
      shipmentData.itemInfo.forEach((item, index1) => {
        this.validataItemDiscription(
          index1,
          errorData.itemInfo[index1].itemDescription,
          item.itemDescription
        );
        this.validatePurpose(
          index1,
          errorData.itemInfo[index1].purpose,
          item.purpose
        );
        this.validateItemValue(
          index1,
          errorData.itemInfo[index1].value,
          item.value
        );
      });

      //validate weigth
      this.validateTotalWeight(errorData.totalWeight, shipmentData);

      //validate package information
      this.validatePackingMethod(
        errorData.packageInfo.packingMethod,
        shipmentData.packageInfo.packingMethod
      );
      if (shipmentData.packageInfo.packingMethod == "box") {
        this.validateWidth(
          errorData.packageInfo.width,
          shipmentData.packageInfo.width
        );
        this.validateLength(
          errorData.packageInfo.length,
          shipmentData.packageInfo.length
        );
        this.validateHeight(
          errorData.packageInfo.height,
          shipmentData.packageInfo.height
        );
      }

      //validate payment information
      this.validatePaymentMethod(
        errorData.preferedPayMode,
        shipmentData.preferedPayMode
      );

      //calculate total errors in this shipment
      if (errorData.receiverInfo.courierService.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.receiverInfo.courierService.msg);
      }
      if (errorData.receiverInfo.country.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.receiverInfo.country.msg);
      }
      if (errorData.receiverInfo.name.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.receiverInfo.name.msg);
      }
      if (errorData.receiverInfo.mobile.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.receiverInfo.mobile.msg);
      }
      if (errorData.receiverInfo.email.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.receiverInfo.email.msg);
      }
      if (errorData.receiverInfo.address1.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.receiverInfo.address1.msg);
      }
      if (errorData.receiverInfo.address2.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.receiverInfo.address2.msg);
      }
      if (errorData.receiverInfo.address3.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.receiverInfo.address3.msg);
      }
      if (errorData.receiverInfo.address4.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.receiverInfo.address4.msg);
      }
      if (errorData.receiverInfo.zipcode.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.receiverInfo.zipcode.msg);
      }
      if (errorData.receiverInfo.company.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.receiverInfo.company.msg);
      }

      errorData.itemInfo.forEach((item, index) => {
        if (item.itemDescription.error == true) {
          errorData.total += 1;
          errorData.errorMsg.push(
            item.itemDescription.msg + ` in item ${index + 1}`
          );
        }
        if (item.purpose.error == true) {
          errorData.total += 1;
          errorData.errorMsg.push(item.purpose.msg + ` in item ${index + 1}`);
        }
        if (item.value.error == true) {
          errorData.total += 1;
          errorData.errorMsg.push(item.value.msg + ` in item ${index + 1}`);
        }
      });

      if (errorData.totalWeight.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.totalWeight.msg);
      }
      if (errorData.packageInfo.packingMethod.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.packageInfo.packingMethod.msg);
      }
      if (errorData.packageInfo.width.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.packageInfo.width.msg);
      }
      if (errorData.packageInfo.length.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.packageInfo.length.msg);
      }
      if (errorData.packageInfo.height.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.packageInfo.height.msg);
      }

      if (errorData.preferedPayMode.error == true) {
        errorData.total += 1;
        errorData.errorMsg.push(errorData.preferedPayMode.msg);
      }
    },

    //to validate COURIERSERVICE field in receiverInfo
    validateCourierService(errorData, data) {
      this.selVali(errorData, data, "Courier Service");
    },
    //to validate COUNTRY field in receiverInfo
    validateCountry(errorData, data) {
      this.selVali(errorData, data, "Country");
    },
    //to validate NAME field in receiverInfo
    validateName(errorData, data) {
      this.strVali(errorData, data, 1, 35, "First Name", "any", true);
    },
    //to validate LASTNAME field in receiverInfo
    validateLastName(errorData, data) {
      this.strVali(errorData, data, 1, 35, "Last Name", "any", true);
    },
    //to validate VATORTAX field in receiverInfo
    validateVatOrTax(errorData, data) {
      this.strVali(errorData, data, 0, 20, "VAT/TAX", "any", false);
    },
    //to validate MOBILE field in receiverInfo
    validateMobile(errorData, data) {
      //required if colombo express is courier service
      if (this.activeShipmentData.receiverInfo.courierService != null)
        if (
          this.activeShipmentData.receiverInfo.courierService.id ==
            "colomboExpress" ||
          this.activeShipmentData.receiverInfo.courierService.id ==
            "colomboEconomy"
        ) {
          this.selVali(errorData, data, "Mobile Number");
        } else {
          errorData.error = false;
          errorData.msg = [];
        }
      else {
        errorData.error = false;
        errorData.msg = [];
      }
    },
    //to validate EMAIL field in receiverInfo
    validateEmail(errorData, data) {
      console.log("no validation for email", errorData, data);
      // this.emailVali(errorData, data);
    },
    //to validate ADDRESS1 field in receiverInfo
    validateAddress1(errorData, data) {
      this.strVali(errorData, data, 0, 30, "Address Line 1", "any", true);
    },
    //to validate ADDRESS2 field in receiverInfo
    validateAddress2(errorData, data) {
      this.strVali(errorData, data, 0, 30, "Address Line 2", "any", true);
    },
    //to validate ADDRESS3 field in receiverInfo
    validateAddress3(errorData, data) {
      this.strVali(errorData, data, 0, 30, "Address Line 3", "any", false);
    },
    //to validate ADDRESS4 field in receiverInfo
    validateAddress4(errorData, data) {
      this.strVali(errorData, data, 0, 30, "Address Line 4", "any", false);
    },
    //to validate ZIPCODE field in receiverInfo
    validateZipcode(errorData, data) {
      this.strVali(errorData, data, 0, 30, "Zip Cod/Postal Code", "any", false);
    },
    //to validate COMPANY field in receiverInfo
    validateCompany(errorData, data) {
      this.strVali(errorData, data, 3, 25, "Company", "any", false);
    },
    //to validate ITEMDESCRIPTION field in iteminfo
    validataItemDiscription(index, errorData, data) {
      this.strVali(errorData, data, 3, 35, "Item Description", "any", true);
    },
    //to validate PURPOSE field in iteminfo
    validatePurpose(index, errorData, data) {
      this.selVali(errorData, data, "Purpose");
    },
    //to validate ITEMVALUE field in receiverInfo
    validateItemValue(index, errorData, data) {
      this.numVali(errorData, data, 0, 1000000, "Item value");
    },
    //to validate TOTALWEIGHT field in receiverInfo
    validateTotalWeight(errorData, data) {
      errorData.msg = null;
      errorData.error = false;

      //null check
      if (
        data.receiverInfo.courierService == null ||
        data.receiverInfo.courierService == undefined
      )
        return 0;
      if (
        data.receiverInfo.country == null ||
        data.receiverInfo.country == undefined
      )
        return 0;

      const totalWeight = parseInt(data.totalWeight);
      if (
        totalWeight == null ||
        totalWeight == undefined ||
        totalWeight == 0 ||
        totalWeight == "" ||
        isNaN(totalWeight) == true
      )
        return 0;

      //calulate upgraded weight
      const updatedWeight = exportCalculator.getUpdatedWeight(
        data.receiverInfo.courierService,
        totalWeight
      );
      console.log("$updatedWeight", updatedWeight);

     

        console.log("receiver Info", data.receiverInfo)

      //logic check
      let isTransportable = exportCalculator.isTransportable(
        data.receiverInfo.courierService,
        data.receiverInfo.country,
        updatedWeight
      );
      console.log("$isTransportablee", isTransportable);

      if (isTransportable == false) {
        errorData.error = true;
        errorData.msg = `${data.totalWeight} grams Cannot be Shiped to ${data.receiverInfo.country.name} Through ${data.receiverInfo.courierService.title} | Contact Our Hotline: +94 755 192 192 For Further Assistance`;
      }
    },
    //to validate PACKINGMETHOD field in packageInfo
    validatePackingMethod(errorData, data) {
      this.selVali(errorData, data, "Packing Method");
    },
    //to validate WIDTH field in packageInfo
    validateWidth(errorData, data) {
      this.numVali(errorData, data, 1, 10000, "Width");
    },
    //to validate LENGTH field in packageInfo
    validateLength(errorData, data) {
      this.numVali(errorData, data, 1, 10000, "Length");
    },
    //to validate HEIGHT field in packageInfo
    validateHeight(errorData, data) {
      this.numVali(errorData, data, 1, 10000, "Height");
    },
    //to validate PAYMENTMETHOD field in preferedPayMode
    validatePaymentMethod(errorData, data) {
      this.selVali(errorData, data, "Payment Method");
    },
    /* 
;
;
;
;
;
;
;
;
;
;
;
*/

    // select field validator
    selVali(errorData, stringDataRaw, title) {
      errorData.msg = null;
      if (
        stringDataRaw == null ||
        stringDataRaw == undefined ||
        stringDataRaw == "" ||
        stringDataRaw == " "
      ) {
        errorData.error = true;
        errorData.msg = `Enter ${title}`;
      } else {
        errorData.error = false;
      }
    },
    //email field validator
    emailVali(errorData, stringDataRaw) {
      errorData.msg = null;
      if (stringDataRaw == null || stringDataRaw == undefined) {
        errorData.error = true;
        errorData.msg = "Enter Email";
      }
      // else if (
      //   this.valueCheck(stringDataRaw.toString().replace(/\s/g, ""), "email") ==
      //   false
      // ) {
      //   errorData.error = true;
      //   errorData.msg.push(`Enter Proper Format`);
      // }
      else if (stringDataRaw.toString().length >= 50) {
        errorData.error = true;
        errorData.msg = "Max length is 50";
      } else {
        errorData.error = false;
      }
    },

    // number strict validator
    numStrVali(errorData, stringDataRaw, length, title, check) {
      errorData.msg = null;
      if (
        stringDataRaw == null ||
        stringDataRaw == undefined ||
        stringDataRaw == ""
      ) {
        errorData.error = true;
        errorData.msg = `Enter ${title}`;
      } else if (
        this.valueCheck(stringDataRaw.toString().replace(/\s/g, ""), check) ==
        false
      ) {
        errorData.error = true;

        errorData.msg = `Enter Only Numbers`;
      } else if (stringDataRaw.toString().length != length) {
        errorData.error = true;

        errorData.msg = `Enter ${length} numbers`;
      } else {
        errorData.error = false;
      }
    },
    //string field validator
    strVali(errorData, stringDataRaw, min, max, title, check, required) {
      errorData.msg = null;
      if (
        stringDataRaw == null ||
        stringDataRaw == undefined ||
        stringDataRaw == ""
      ) {
        errorData.error = false;
        if (required) {
          errorData.error = true;
          errorData.msg = `Enter ${title}`;
        }
      } else {
        if (this.valueCheck(stringDataRaw, check) == false) {
          errorData.error = true;

          if (check == "alph") errorData.msg = `Only alphabets are allowed`;
          else if (check == "alphNum")
            errorData.msg = `Only  alphabets & numbers are allowed`;
        } else if (stringDataRaw.length < min) {
          errorData.error = true;

          errorData.msg = `Enter atleast ${min} letters`;
        } else if (stringDataRaw.length > max) {
          errorData.error = true;

          errorData.msg = `Max length is ${max} characters`;
        } else {
          errorData.error = false;
        }
      }
    },
    //number field validator
    numVali(errorData, stringDataRaw, min, max, title) {
      errorData.msg = null;
      if (
        stringDataRaw == null ||
        stringDataRaw == undefined ||
        stringDataRaw == ""
      ) {
        errorData.error = true;
        errorData.msg = `Enter ${title}`;
      } else if (this.valueCheck(stringDataRaw, "num") == false) {
        errorData.error = true;
        errorData.msg = `Only numbers are allowed`;
      } else if (stringDataRaw < min) {
        errorData.error = true;

        errorData.msg = `Min value is ${min}`;
      } else if (stringDataRaw > max) {
        errorData.error = true;

        errorData.msg = `Max value is ${max}`;
      } else {
        errorData.error = false;
      }
    },

    /*
       To check characters of a value
       @value - any set of characters
       @check - alpha || alphaNum || num || email
    */
    valueCheck(value, check) {
      if (check == "any") {
        return true;
      }
      if (check == "alph") {
        if (value.search(/[^a-zA-Z]+/) === -1) return true;
        else return false;
      } else if (check == "alphNum") {
        if (value.search(/[^a-zA-Z0-9]+/) === -1) return true;
        else return false;
      } else if (check == "num") {
        if (value.search(/[^0-9]+/) === -1) return true;
        else return false;
      } else if (check == "email") {
        const filter =
          /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/;
        if (value.search(filter) === -1) return true;
        else return false;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  background-color: rgb(110, 34, 34);
  height: 150px;
  padding: 0%;
}
.formSection {
  width: 100%;
}
a {
  text-decoration: none;
}
.item {
  display: flex;
}
.l_1,
.courier {
  display: flex;
}

.btn {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.packageinfo {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .l_1 {
    flex-direction: column;
    // align-items: center;
    justify-content: center;
  }
  .btn {
    flex-direction: row;
  }
}
</style>